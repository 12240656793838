import { useRouter } from 'next/router';
import { useCallback, useMemo } from 'react';
import { NormalizedVariant } from '@ts/product';
import { ProductView } from '@components';
import { isEmpty } from '@utils/objects';
import { useProductQuery } from '@services/shopify';
import { ADD_TO_CART, ALL_BASE_FRAME_HANDLES, BASE_FRAME_NAMES, LARGE_WIDTH, LOCALE_DICT, TOP_FRAMES_PATH } from '@constants';
import { useIsMobile } from '@utils/hooks';
import { reduceBaseFrame } from '@utils/shopify';
import { CART_LINE_ATTRIBUTE_KEYS } from '@utils/constants/cart';
import { useCartContext } from '@context';

type TopSidebarProps = {
	product;
	productTags;
	yotpo;
	baseFrameVariantsAvailable;
	frameShape: (typeof BASE_FRAME_NAMES)[number];
	frameSwatches;
	setFrameShape;
	activeFrameColor;
	handleActiveFrameColorChange;
};

function TopSidebar({
	product,
	frameShape,
	setFrameShape,
	productTags,
	yotpo,
	frameSwatches,
	baseFrameVariantsAvailable,
	activeFrameColor,
	handleActiveFrameColorChange,
}: TopSidebarProps) {
	const { asPath, pathname, query, replace, locale } = useRouter();
	const { handleCartAdd, isCartMutating } = useCartContext();
	const isMobile = useIsMobile({ maxWidth: LARGE_WIDTH });

	const { description, name, price, type, descriptionHtml } = product;
	const activeBaseFrameHandle = Object.entries(ALL_BASE_FRAME_HANDLES).find(
		([handle, frame]) => frame === frameShape && handle
	)[0];

	const { data: baseFrameData } = useProductQuery(
		activeBaseFrameHandle,
		{ includeDescription: false, skipCollections: true, skipVariants: true },
		{
			queryKey: `base-frame-variant-${frameShape}`,
			queryRefreshVars: [activeBaseFrameHandle, frameShape],
		}
	);

	const { data: variant, isLoading }: { data: NormalizedVariant; isLoading: boolean } = useProductQuery(
		product.handle,
		{
			selectedOptions: [{ name: 'Frame', value: frameShape }],
			includeSpecificFrameVariant: true,
			country: LOCALE_DICT[locale].countryCode,
		},
		{
			queryKey: 'top-frame-variants',
			queryRefreshVars: [frameShape, product.handle, locale],
		}
	);

	const baseFrame = useMemo(
		() => (baseFrameData ? reduceBaseFrame(baseFrameData, activeFrameColor) : null),
		[activeFrameColor, baseFrameData]
	);

	const shipmentInfo = variant?.metafields?.shipmentInfo;

	const updateFrameShape = useCallback(
		(frame: (typeof BASE_FRAME_NAMES)[number]) => {
			setFrameShape(frame);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[pathname, query, replace]
	);

	return (
		<div>
			<ProductView.Sidebar
				path={asPath}
				product={product}
				selectedVariant={!isLoading && variant}
				name={name}
				subtotal={Number(price.amount)}
				updateFrameShape={updateFrameShape}
				productType={type}
				productTags={productTags}
				yotpo={yotpo}
				shipmentInfo={shipmentInfo}
				primaryController={{
					controllerType: 'shape',
					title: 'Base Frame Shape',
					buttonProps: {
						fullWidth: true,
						color: 'transparent-dark',
						withChevron: true,
						chevronDirection: 'down',
						label: frameShape,
						spaceBetween: true,
					},
				}}
				colorController={{
					controllerType: 'frame',
					title: frameShape,
					selected: activeFrameColor,
					options: baseFrame ? baseFrame.colorOptions : [],
					variantImages: baseFrame ? baseFrame.images : [],
					callback: handleActiveFrameColorChange,
				}}
				collectionController={
					!isEmpty(frameSwatches) && {
						controllerType: 'option',
						title: 'More From This Collection',
						options: Object.keys(frameSwatches[frameShape]),
						selected: product?.metafields?.productSwatch?.url,
						frameSwatches: frameSwatches[frameShape],
					}
				}
				aboutProps={{
					description: description,
					descriptionHtml: descriptionHtml,
					name: name,
					productType: type,
					selectedFrame: frameShape,
				}}
				buttonProps={{
					label: `+ ${ADD_TO_CART}`,
					onClick: ref => {
						handleCartAdd(
							[
								{
									variant: !isLoading && variant,
									customAttributes: [
										{
											key: CART_LINE_ATTRIBUTE_KEYS.COLLECTION_PATH,
											value: `${TOP_FRAMES_PATH}/${query?.collection}/`,
										},
									],
								},
							],
							true,
							ref
						);
					},
					disabled: isCartMutating,
					hasSubtotal: false,
					withChevron: false,
					price: price.amount,
					currencyCode: price.currencyCode,
					dataTags: { 'data-add-to-cart': name },
					availableForSale: !isLoading ? variant?.availableForSale : false,
					margin: isMobile ? '0.8rem 0 0' : '1.6rem 0 0',
				}}
				variantsAvailable={new Set(baseFrameVariantsAvailable)}
				frameShape={frameShape}
			/>
		</div>
	);
}

export default TopSidebar;
